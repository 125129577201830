<template>
  <v-container fluid class="mb-16">
    <p class="text-h5 text-left">기술이전 신청</p>
    <div style="text-align: left; border: 1px solid #aaaaaa; padding: 20px">
      <label class="mr-2" style="display: inline-block; width: 100px; text-align: right">업무절차</label>
      <input
        v-model="param.workFlowCodes"
        class="ml-4 mr-2"
        type="checkbox"
        id="workFlowCodeAll"
        value="ALL"
        @change="onChangeWorkFlowCode">
      <label for="workFlowCodeAll">전체</label>
      <template v-for="code in codes['WKFLW']">
        <input
          v-if="code.etc1 === 'R'"
          v-model="param.workFlowCodes"
          class="ml-4 mr-2"
          type="checkbox"
          :key="code.codeDetailId + '_1'"
          :id="code.codeDetailId"
          :value="code.codeDetailId"
          @change="onChangeWorkFlowCode">
        <label v-if="code.etc1 === 'R'" :key="code.codeDetailId + '_2'" :for="code.codeDetailId">{{ code.codeDetailName }}</label>
      </template>
      <button type="button" class="pp_s ml-12" @click="onClickSearch">검색</button>
      <button type="button" class="pp_s ml-2" style="background-color: #42b98380" @click="openWrite">등록</button>
    </div>

    <table class="popup-table mt-6" style="width: 100%">
      <colgroup>
        <col width="4%">
        <col width="6%">
        <col width="6%">
        <col width="9%">
        <col width="9%">
        <col width="11%">
        <col width="9%">
        <col/>
        <col width="9%">
        <col width="9%">
        <col width="9%">
        <col width="9%">
      </colgroup>
      <thead>
      <tr>
        <th class="td_dark_blue" rowspan="2">NO.</th>
        <th class="td_dark_blue" rowspan="2" @click="setSort('applyNo')">신청번호 <v-icon v-if="checkSort('applyNo')" small>{{ checkSort('applyNo') }}</v-icon></th>
        <th class="td_dark_blue" rowspan="2" @click="setSort('workFlowName')">업무절차 <v-icon v-if="checkSort('workFlowName')" small>{{ checkSort('workFlowName') }}</v-icon></th>
        <th class="td_dark_blue" colspan="2">기본정보</th>
        <th class="td_dark_blue" colspan="2">업체정보</th>
        <th class="td_dark_blue" colspan="2">기술 및 지식재산권</th>
        <th class="td_dark_blue" colspan="2">계약</th>
        <th class="td_dark_blue">관련 연구과제</th>
      </tr>
      <tr>
        <th class="td_dark_blue" @click="setSort('techTransDate')">기술이전계약일 <v-icon v-if="checkSort('techTransDate')" small>{{ checkSort('techTransDate') }}</v-icon></th>
        <th class="td_dark_blue" @click="setSort('techTransManagerName')">기술이전책임자 <v-icon v-if="checkSort('techTransManagerName')" small>{{ checkSort('techTransManagerName') }}</v-icon></th>
        <th class="td_dark_blue" @click="setSort('companyName')">업체명 <v-icon v-if="checkSort('companyName')" small>{{ checkSort('companyName') }}</v-icon></th>
        <th class="td_dark_blue" @click="setSort('registerNo')">사업자등록번호 <v-icon v-if="checkSort('registerNo')" small>{{ checkSort('registerNo') }}</v-icon></th>
        <th class="td_dark_blue" @click="setSort('techType')">대표기술유형 <v-icon v-if="checkSort('techType')" small>{{ checkSort('techType') }}</v-icon></th>
        <th class="td_dark_blue" @click="setSort('techName')">대표기술명 <v-icon v-if="checkSort('techName')" small>{{ checkSort('techName') }}</v-icon></th>
        <th class="td_dark_blue" @click="setSort('transactionType')">거래유형 <v-icon v-if="checkSort('transactionType')" small>{{ checkSort('transactionType') }}</v-icon></th>
        <th class="td_dark_blue" @click="setSort('techFeeReceiptType')">기술료수취유형 <v-icon v-if="checkSort('techFeeReceiptType')" small>{{ checkSort('techFeeReceiptType') }}</v-icon></th>
        <th class="td_dark_blue" @click="setSort('labNames')">관리 연구소 <v-icon v-if="checkSort('labNames')" small>{{ checkSort('labNames') }}</v-icon></th>
      </tr>
      </thead>
      <thead v-if="loading">
      <tr>
        <th colspan="12" class="td_loading">
          <v-progress-linear
            indeterminate
            color="#0f2585"
          ></v-progress-linear>
        </th>
      </tr>
      </thead>
      <tbody v-if="items.length > 0">
      <template v-for="(item,index) in items">
        <tr :key="index" :class="{'even': index % 2 > 0}">
          <td class="td_blue cen bo" @click="setSelected(index)" @dblclick="itemSelect(item)">{{ index + 1 }}</td>
          <td class="td_blue cen bo" @click="setSelected(index)" @dblclick="itemSelect(item)">{{ item.applyNo }}</td>
          <td class="td_blue cen bo" @click="setSelected(index)" @dblclick="itemSelect(item)">{{ item.workFlowName }}</td>
          <td class="td_blue cen bo" @click="setSelected(index)" @dblclick="itemSelect(item)">{{ dateFormat(item.techTransDate) }}</td>
          <td class="td_blue cen bo" @click="setSelected(index)" @dblclick="itemSelect(item)">{{ item.techTransManagerName }}</td>
          <td class="td_blue cen bo" @click="setSelected(index)" @dblclick="itemSelect(item)">{{ item.companyName }}</td>
          <td class="td_blue cen bo" @click="setSelected(index)" @dblclick="itemSelect(item)">{{ bizRegisterNoFormat(item.registerNo) }}</td>
          <td class="td_blue cen bo" @click="setSelected(index)" @dblclick="itemSelect(item)">{{ getCodeDetailName('7030', item.techType) }}</td>
          <td class="td_blue cen bo" @click="setSelected(index)" @dblclick="itemSelect(item)">{{ item.techName }}</td>
          <td class="td_blue cen bo" @click="setSelected(index)" @dblclick="itemSelect(item)">{{ getCodeDetailName('7010', item.transactionType) }}</td>
          <td class="td_blue cen bo" @click="setSelected(index)" @dblclick="itemSelect(item)">{{ getCodeDetailName('7040', item.techFeeReceiptType) }}</td>
          <td class="td_blue cen bo" @click="setSelected(index)" @dblclick="itemSelect(item)">
            <div
                v-for="(labName, index) in item.labNameList"
                :key="index"
            >
              {{ labName }}
            </div>
          </td>
        </tr>
      </template>
      </tbody>
      <tbody v-else>
      <tr>
        <td class="no-data" colspan="12">데이터를 찾을 수 없습니다.</td>
      </tr>
      </tbody>

    </table>
    <loading-dialog :loading="initLoading"/>
  </v-container>
</template>

<script>
// import moment from 'moment'
import LoadingDialog from '@/views/dialog/LoadingDialog'
export default {
  name: 'ContractTechReqList',
  components: {LoadingDialog},
  data() {
    return {
      initLoading: false,
      param: {
        workFlowCodes: ['ALL'],
        sort: '',
        startDate: null,
        endDate: null,
        searchType: null
      },
      loading: false,
      codes: {},
      items: [],
      selectedIndex: -1
    }
  },
  mounted() {
    this.getCodeDetails()
    .then(() => {
      const query = this.$route.query
      if (query && query.sw) {
        this.param.workFlowCodes = [query.sw]
        this.param.searchType = query.sw
      }
      if (query && query.startDate) {
        this.param.startDate = query.startDate
      }
      if (query && query.endDate) {
        this.param.endDate = query.endDate
      }
      this.search();
    })
  },
  methods: {
    onClickSearch() {
      this.param.startDate = null
      this.param.endDate = null
      this.param.searchType = null
      this.search()
    },
    search() {
      this.loading = true
      this.$http.get('/api/contract/apply', {params: this.param})
      .then(({ data }) => {
        this.selectedIndex = -1
        this.items = data
        this.loading = false
      })
    },
    setSelected(index) {
      this.selectedIndex = index
    },
    itemSelect(item) {
      window.open(`/application/#/apply/${item.contractTechId}`, item.contractTechId, 'menubar=no,width=1600,height=1000')
    },
    getCodeDetails() {
      this.initLoading = true
      return this.$http.get('/api/sys/code/detail/all')
      .then(({ data }) => {
        this.codes = data
      })
      .finally(() => this.initLoading = false)
    },
    getCodeDetailName(codeId, codeDetailId) {
      for (let code of this.codes[codeId]) {
        if (code.codeDetailId === codeDetailId) {
          return code.codeDetailName
        }
      }
      return ''
    },
    openWrite() {
      window.open(`/application/#/write`, 'contractTechWrite', 'menubar=no,width=1600,height=1000')
    },
    onChangeWorkFlowCode(e) {
      if (e.target.value === 'ALL') {
        if (e.target.checked) {
          this.param.workFlowCodes = ['ALL']
        }
      } else {
        const idx = this.param.workFlowCodes.indexOf('ALL')
        if (idx >= 0) {
          this.param.workFlowCodes.splice(idx, 1)
        }
      }
      this.search()
    },
    setSort(fieldName) {
      const sort = this.param.sort
      if (sort.indexOf(fieldName) > -1) {
        if (sort.indexOf(',desc') > 0) {
          this.param.sort = ''
        } else {
          this.param.sort = `${fieldName},desc`
        }
      } else {
        this.param.sort = `${fieldName},asc`
      }
      this.search()
    },
    checkSort(fieldName) {
      const sort = this.param.sort
      if (sort.indexOf(fieldName) > -1) {
        if (sort.indexOf(',desc') > 0) {
          return 'mdi-arrow-down'
        } else {
          return 'mdi-arrow-up'
        }
      } else {
        return ''
      }
    },
  }
}
</script>
